import React, { useEffect, useState, useCallback } from 'react'
import { Saved } from "components/elements/saved"

function LazyBackground({
  src,
  className,
  children,
  place,
  handleSave,
  isImage, // True if image is not placeholder
  isVisible,
  isSaved,
  isShowSaved,
  isShow,
  addAspectRatio = false,
  style
}) {
  const timeout = 500
  const [aspectRatio, setAspectRatio] = useState(1)
  const [source, setSource] = useState(src)
  const [dominantColor, setDominantColor] = useState(null);

  const [showImage, setShowImage] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isLowQuality, setIsLowQuality] = useState(false)
  const [imageStyle, setImageStyle] = useState({})

  const onLoad = () => {
    if (!imageLoaded) {
      setSource(src)
      setShowImage(true)
      setImageLoaded(true)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!imageLoaded) onLoad()
    }, timeout)
    return () => clearTimeout(timer)
  }, [imageLoaded, onLoad, timeout])


  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      onLoad()
      const { width, height } = img

      const aspectRatio = width / height
      const is_low_quality = height < 270

      setAspectRatio(aspectRatio)
      setIsLowQuality(is_low_quality)

    }
  }, [src, onLoad])

  // Class for aspect ratio
  // TODO: Make this a separate component or hook
  const [aspectRatioClass, setAspectRatioClass] = useState('aspect-ratio-base')

  useEffect(() => {
    switch (true) {
      case aspectRatio <= 0.5:
        setAspectRatioClass('aspect-ratio-tall-portrait');
        break;
      case aspectRatio === 1:
        setAspectRatioClass('aspect-ratio-square');
        break;
      case aspectRatio < 1.5:
        setAspectRatioClass('aspect-ratio-portrait');
        break;
      case aspectRatio >= 2:
        setAspectRatioClass('aspect-ratio-wide-landscape');
        break;
      case aspectRatio > 1.5:
        setAspectRatioClass('aspect-ratio-landscape');
        break;
      default:
        setAspectRatioClass('aspect-ratio-base');
        break;
    }
  }, [aspectRatio]);


  useEffect(() => {
    // Simple technique to strech the most dominant color of the image as a pixel
    const dominantColor = src.replace('tr=w-480', 'tr=w-8,h-8:w-450,h-300,bl-20')
    setDominantColor(dominantColor)
  }, [src]);


  const containerClassname = `background-image${className ? ' ' + className : ''} ${addAspectRatio && aspectRatioClass}`
  const hasImage = typeof source !== 'undefined'
  const savedIcon = (
    <Saved
      place={place}
      handleClick={handleSave}
      isSaved={isSaved}
    />
  )

  useEffect(() => {
    const newStyle = hasImage
      ? {
        backgroundImage: `url(${dominantColor})`,
        content: source ? `url(${source})` : style?.background ? style.background : 'none',
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        //filter: isLowQuality ? 'blur(1px)' : 'none'
      }
      : {
        ...style
      }

    setImageStyle(newStyle)

  }, [source, dominantColor, style, isLowQuality])

  const isReady = imageLoaded || isVisible || showImage

  return (
    <div className='sing-card-image'>
      {isReady
        ? (
          <div
            className={containerClassname}
            onLoad={onLoad}
            style={imageStyle}
          >
            {isShowSaved ? savedIcon : ''}

            {children}
          </div>
        )
        : (
          <div className={`background-image`}></div>
        )
      }
    </div>
  )
}

export default LazyBackground